import { getSumByKey } from '@utilities';

export const calculateSum = fields => getSumByKey(fields, 'value');

export const calculateActiveFields = fields => {
    return fields.filter(field => field.value);
}

export const calculateWeightedReturn = fields => {
    const sum = calculateSum(fields);

    return fields.reduce((a, b) => {
        const weight = b.value / sum;
        const weightedReturn = weight * b.return;

        return a + weightedReturn;
    }, 0);
}

export const calculateAccumulatedReturn = (fields, years) => {
    const weightedReturn = calculateWeightedReturn(fields);

    const annualReturn = [...Array(years).keys()].map((_, index) => {
        return Math.pow((1 + weightedReturn), index) - 1;
    })

    return annualReturn;
}

export const calculateAccumulatedCapital = (accumulatedReturn, initialCapital) => {
    return accumulatedReturn.map((yearReturn) => {
        return initialCapital * (yearReturn + 1);
    })
}

export const calculateRisk = ({ fields, variance }) => {
    const sum = calculateSum(fields);

    if (sum === 0) return 0;

    const risk = fields.reduce((a, b) => {
        const weightedRisk = Math.pow(b.value / sum, 2) * Math.pow((b.risk * 100), 2);
        return a + weightedRisk;
    }, 0);

    const totalVariance = variance.reduce((a, b) => {
        const bValue = b.reduce((c, d) => c + d);

        return a + bValue;
    }, 0);

    const totalRisk = Math.sqrt(risk + totalVariance);

    return totalRisk;
}

export const calculateKovariere = ({ correlations, fields }) => {
    return correlations.map((correlationRow, correlationRowIndex) => {
         return correlationRow.map((correlation, correlationIndex) => {

            if (correlation === null) return null;
             
            const rowRisk = fields[correlationRowIndex].risk * 100;
            const columnRisk = fields[correlationIndex].risk * 100;

            return rowRisk * columnRisk * correlation;
        });
    });
}

export const calculateVariance = ({ fields, kovarieres }) => {
    const sum = calculateSum(fields);

    return kovarieres.map((kovarieresRow, kovarieresRowIndex) => {
        return kovarieresRow.map((kovarieres, kovarieresIndex) => {
            if (kovarieres === null) return null;

            const rowValue = fields[kovarieresRowIndex].value
            const columnValue= fields[kovarieresIndex].value;

            if (!rowValue || !columnValue) return 0;

            const rowWeight = rowValue / sum;
            const columnWeight = columnValue / sum;

            return 2 * rowWeight * columnWeight * kovarieres;
        })
    })
}

export const calculateCo2Savings = ({ fields }) => {
    return fields.reduce((a, b) => {
        const co2Savings = b.co2Savings * ( b.value / 1000000);
        return a + co2Savings;
    }, 0);
}