import React from 'react';
import cx from 'classnames';
import { Spring, config } from 'react-spring/renderprops';
import NumberFormat from 'react-number-format';
import { usePrevious } from '@utilities';
import arrow from '@static/obton-icon-arrow.svg';

import './counter-badge.scss';

export const CounterBadge = ({ value, className, show }) => {
   
    const isNegative = value < 0;
    const isInifinty = value === Infinity;
    const prevValue = usePrevious(isInifinty ? 0 : value);

    return (
        <div className={cx('counter-badge', {'counter-badge--negative': isNegative}, className)}>
            <div className="counter-badge__wrapper">
                <img src={arrow} alt="" className="counter-badge__icon" />
                {isInifinty && <span className="counter-badge__value">∞</span>}
                {!isInifinty &&
                    <Spring 
                        config={{precision: 0.001, ...config.slow}}
                        from={{ number: prevValue }}
                        to={{ number: value }}>
                        {props => (
                            <NumberFormat
                            allowNegative={false}
                            className="counter-badge__value"
                            suffix="%"
                            value={Math.round(props.number * 10) / 10}
                            decimalSeparator={','}
                            displayType={'text'} />
                        )}
                        </Spring>
                }
            </div>
        </div>
    )
}