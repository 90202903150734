import React from 'react';
import cx from 'classnames';
import { Spring, config } from 'react-spring/renderprops';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { usePrevious } from '@utilities';

import './counter.scss';

export const Counter = ({ value, suffix, label, className }) => {

    const prevValue = usePrevious(value);

    return (
        <div className={cx('counter', className)}>
            <Spring 
                config={{precision: 0.001, ...config.slow}}
                from={{ number: prevValue }}
                to={{ number: value }}>
                {props => (
                    <NumberFormat
                    className="counter__value"
                    suffix={suffix}
                    value={Math.round(props.number * 10) / 10}
                    decimalSeparator={','}
                    displayType={'text'} />
                )}
                </Spring>
            {label && <p className="counter__label">{label}</p>}
        </div>
    )
}

Counter.defaultProps = {
    value: 0,
    suffix: '%'
}

Counter.propTypes = {
    label: PropTypes.string,
    suffix: PropTypes.string
}