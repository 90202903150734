import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './label.scss';

export const Label = ({ children, className }) => (
    <div className={cx('label', className)}>
        {children}
    </div>
)

Label.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.string,
	])
}