import React from 'react';
import { DonutChart, Counter, ChartLabel, CounterBadge } from '@components';
import { getDonutChartColor } from '@utilities';

export const ReturnCardOverview = ({ activeFields, texts, annualReturn, risk, co2Savings, annualReturnDiff, riskDiff }) => (
    <div className="return-card__overview">
        <div className="grid grid--align-center margin-bottom--lg grid--wrap">
            <div className="grid__cell grid__cell--width-12 grid__cell--width-6@sm">
                <DonutChart className="return-card__donut" data={activeFields} />
            </div>
            <div className="grid__cell grid__cell--width-12 grid__cell--width-6@sm">
                <div className="grid grid--wrap">
                    <div className="grid__cell grid__cell--width-6 grid__cell--width-12@sm return-card__count">
                        <Counter className="margin-bottom--2xs" value={annualReturn} label={texts?.expectedReturn} />
                        {annualReturnDiff != null && <CounterBadge value={annualReturnDiff} />}
                    </div>
                    <div className="grid__cell grid__cell--width-6 grid__cell--width-12@sm grid__cell--align-right return-card__count">
                        <div>
                            <Counter className="margin-bottom--2xs" value={risk} label={texts?.risk} />
                            {riskDiff != null && <CounterBadge value={riskDiff} show={riskDiff !== 0} />}
                        </div>
                    </div>
                    {/* 
                   Saved co2 savings if they need it back.
                   <div className="grid__cell  return-card__count">
                        <div className="grid grid--no-gutter">
                            <Counter value={co2Savings} suffix="t" label={texts?.co2Savings} />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        <div className="grid grid--row-gap grid--wrap">
            {activeFields.map((field, index) => (
                <ChartLabel key={index} className="grid__cell grid__cell--width-12 grid__cell--width-6@sm" color={getDonutChartColor(field.id)}>{field.title}</ChartLabel>
            ))}
        </div>
    </div>
)