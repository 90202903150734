import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '@components';
import { getData } from '@app/appSlice';
import { CurrentReturn } from '@features/CurrentReturn/CurrentReturn';
import { SimulatedReturn } from '@features/SimulatedReturn/SimulatedReturn';
import { Calculations } from '@features/Calculations/Calculations';
import AnimateHeight from 'react-animate-height';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

export const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  const { loading } = useSelector(state => state.app);
  const { activeFields: currentReturnActiveFields } = useSelector(state => state.currentReturn);
  
  return (
    <Router>
      <div className="app">
        <Header />
          <Switch>
            <Route exact path="/">
              {!loading &&
                <div className="container">
                  <div className="margin-bottom--2xl">
                    <CurrentReturn />
                  </div>
                  <AnimateHeight animateOpacity={true} height={ currentReturnActiveFields.length ? 'auto' : 0}  duration={ 300 }>
                    <div className="margin-bottom--2xl">
                      <SimulatedReturn />
                    </div>
                  </AnimateHeight>
                </div>
              }
            </Route>
            <Route path="/calculations">
              <Calculations />
            </Route>
            <Redirect from="*" to="/" />
          </Switch>
      </div>
    </Router>
  );
}