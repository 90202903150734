import React from 'react';
import PropTypes from 'prop-types';
import { Spring, config } from 'react-spring/renderprops';
import { usePrevious } from '@utilities';
import { InputField } from '@components';

import './total-field.scss';

export const TotalField = ({ prefix, value }) => {
    const prevValue = usePrevious(value);

    return (
        <div className="total-field">
            <Spring 
                config={{precision: 0.001, ...config.slow}}
                from={{ number: prevValue }}
                to={{ number: value }}>
                {props => (
                    <InputField value={Math.round(props.number)} prefix={prefix} displayType="text" />
                )}
            </Spring>
        </div>
    )
}

TotalField.propTypes = {
    currency: PropTypes.string,
    value: PropTypes.number
}