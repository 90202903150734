import React from 'react';
import { FormField } from '@containers';
import { InputField, InputLabel } from '@components';

export const ReturnCardFormField = ({ field, currency, onValueChange }) => (
    <FormField>
        <div className="grid grid--align-center grid--wrap">
            <div className="grid__cell grid__cell--width-12 grid__cell--width-7@sm grid__cell--print">
                <InputLabel description={field.desc} forId={`formfield-${field.id}`} tooltip={field.info}>{field.title}</InputLabel>
            </div>
            <div className="grid__cell grid__cell--width-12 grid__cell--width-5@sm grid__cell--print">
                <div className="grid grid--no-gutter grid--align-right">
                    <InputField value={field.value}
                                prefix={currency}
                                onValueChange={({ floatValue }) => onValueChange(floatValue ?? null) }
                                id={`formfield-${field.id}`} />
                </div>
            </div>
        </div>
    </FormField>
)