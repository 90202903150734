import React, { useState } from 'react';
import { Form, FormField } from '@containers';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import { Label, TotalField, Toggle, LinkButton } from '@components';
import { ReturnCardFormField } from './ReturnCardFormField/ReturnCardFormField';
import transferIcon from '@static/obton_transfer.svg';

export const ReturnCardForm = ({ fields, currency, onChange, sum, transferFields }) => {
    const texts = useSelector(state => state?.app?.dictionary);

    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const initialFields = 5;

    return (
        <Form>
            {transferFields &&
                <div className="return-card__transfer print-hidden">
                    <LinkButton onClick={transferFields}>
                        <img src={transferIcon} alt="" className="link__icon margin-right--xs" />
                        <span className="link__text">{texts?.transfer}</span>
                    </LinkButton>
                </div>
            }
            {fields && fields.slice(0, initialFields).map((field, index) => (
                <ReturnCardFormField key={index} field={field} currency={currency} onValueChange={(floatValue) => { onChange({floatValue, id: field.id })}} />
            ))}
            <AnimateHeight animateOpacity={true} height={ showMore ? 'auto' : 1}  duration={ 300 }>
                {fields && fields.slice(initialFields).map((field, index) => (
                        <ReturnCardFormField key={index} field={field} currency={currency} onValueChange={(floatValue) => { onChange({floatValue, id: field.id })}} />
                    )
                )}
            </AnimateHeight>
            <Toggle openText={texts?.showAssets} closeText={texts?.hideAssets} className="return-card__showmore print-hidden" isOpen={showMore} clickHandler={toggleShowMore} />
            <FormField>
                <div className="grid grid--align-center grid--wrap">
                    <div className="grid__cell grid__cell--width-12 grid__cell--width-7@sm">
                        <Label>{texts?.totalSum}</Label>
                    </div>
                    <div className="grid__cell grid__cell--width-12 grid__cell--width-5@sm">
                        <div className="grid grid--no-gutter grid--align-right">
                            <TotalField value={sum} prefix={currency} />
                        </div>
                    </div>
                </div>
            </FormField>
        </Form>
    )
}