import { createSlice } from '@reduxjs/toolkit';
import { calculateAccumulatedReturn, calculateActiveFields, calculateSum, calculateAccumulatedCapital, calculateRisk, calculateCo2Savings, calculateVariance } from '@app/helpers';

const initialState = {
    fields: [],
    activeFields: [],
    accumulatedReturn: [],
    accumulatedCapital: [],
    yearOneReturn: 0,
    co2Savings: 0,
    risk: 0,
    sum: 0
};

const app = createSlice({
    name: 'simulatedReturn',
	initialState,
	reducers: {
        updateFields(state, action) {
            const { fields, kovarieres } = action.payload;

            const sum = calculateSum(fields);
            const activeFields = calculateActiveFields(fields);
            const accumulatedReturn = calculateAccumulatedReturn(activeFields, 11);
            const accumulatedCapital = calculateAccumulatedCapital(accumulatedReturn, sum);
            const co2Savings = calculateCo2Savings({ fields: activeFields });
            const yearOneReturn = accumulatedReturn[1] ? accumulatedReturn[1] * 100 : 0;
            const variance = calculateVariance({ fields, kovarieres });
            const risk = calculateRisk({ fields, variance });

            state.sum = sum;
            state.activeFields = activeFields;
            state.accumulatedReturn = accumulatedReturn;
            state.accumulatedCapital = accumulatedCapital
            state.co2Savings = co2Savings;
            state.yearOneReturn = yearOneReturn;
            state.fields = fields;
            state.variance = variance;
            state.risk = risk;
        }
	}
});

export const getYearOneReturn = state => state.simulatedReturn.accumulatedReturn[1] ? state.simulatedReturn.accumulatedReturn[1] * 100 : 0;

export const getReturnSummary = state => {
    const currentAccumulatedCapital = state.currentReturn.accumulatedCapital;
    const simulatedAccumulatedCapital = state.simulatedReturn.accumulatedCapital;
   
    return [...Array(11).keys()].map((_, index) => {
        return {
            name: `År ${index}`,
            simulated: Math.round(simulatedAccumulatedCapital[index] ?? 0),
            current: Math.round(currentAccumulatedCapital[index] ?? 0)
        }
    });
};

export const getAnnualReturnDiff = state => {
    const currentAnnualReturn = state.currentReturn.accumulatedReturn[1];
    const simulatedAnnualReturn = state.simulatedReturn.accumulatedReturn[1];

    if (currentAnnualReturn === 0 && simulatedAnnualReturn === 0) return 0;
    
    const diff = currentAnnualReturn - simulatedAnnualReturn;
    const annualReturnDiff = (diff / currentAnnualReturn) * -100;

    return annualReturnDiff;
}

export const getRiskDiff = state => {
    const currentRisk = state.currentReturn.risk;
    const simulatedRisk = state.simulatedReturn.risk;

    if (currentRisk === 0 && simulatedRisk === 0) return 0;

    const diff = currentRisk - simulatedRisk;
    const riskDiff = (diff / currentRisk) * -100;

    return riskDiff;
}

const { reducer, actions } = app;

export const { updateFields } = actions;

export default reducer;
