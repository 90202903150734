import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './link.scss';

export const LinkButton = ({ children, onClick, className }) => (
    <button type="button" onClick={onClick} className={cx('link', className)}>{children}</button>
)

LinkButton.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.string,
    ]),
    onClick: PropTypes.func
}