import React from 'react';
import { useSelector } from 'react-redux';

import './calculations.scss';

export const Calculations = () => {

    const { correlations, fields } = useSelector(state => state.app);

    const decimalToPercent = value => Math.round((value * 100) * 100) / 100;

    return (
        <div className="calculations">
            <section>
                <h1>Værdier</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Felt</th>
                            <th>Afkast</th>
                            <th>Standardafvigelse</th>
                            <th>CO2 besparelse</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((field, index) => (
                            <tr key={index}>
                                <td>{field.title}</td>
                                <td>{decimalToPercent(field.return)}%</td>
                                <td>{decimalToPercent(field.risk)}%</td>
                                <td>{Math.round((field.co2Savings * 1000) * 1000) / 1000}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
            <section>
                <h1>Korrelationer</h1>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            {fields.map((field, index) => (
                                <th key={index}>{field.title}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {correlations.map((correlationsRow, correlationsRowIndex) => (
                            <tr key={correlationsRowIndex}>
                                <th>{fields[correlationsRowIndex].title}</th>
                                {correlationsRow.map((value, index) => (
                                    <td key={index}>{value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </div>
    )
}