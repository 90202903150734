import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import initialData from '@utilities/data.json';
import initialDictionary from '@utilities/dictionary.json';
import { calculateKovariere } from "@app/helpers";
import { updateFields as updateCurrentReturnFields } from '@features/CurrentReturn/currentReturnSlice';
import { updateFields as updateSimulatedReturnFields } from '@features/SimulatedReturn/simulatedReturnSlice';

export const getData = createAsyncThunk('app/getData', 
    async (_, { dispatch, getState }) => {

        const data = initialData;
        const dictionary = initialDictionary;

        const uniqueId = btoa(JSON.stringify(data));
        const { app } = getState();
        const { dataId } = app;

        if (uniqueId === dataId) return { dictionary };

        const { fields, correlations } = data;

        const kovarieres = calculateKovariere({ correlations, fields });
                
        dispatch(updateCurrentReturnFields({ fields, kovarieres }));
        dispatch(updateSimulatedReturnFields({ fields, kovarieres }));

        return { uniqueId, kovarieres, dictionary, correlations, fields };
});

const initialState = {
    dictionary: null,
    fields: [],
    dataId: null,
    loading: false,
    correlations: [],
    kovarieres: []
};

const app = createSlice({
    name: 'app',
	initialState,
	reducers: {
        setKovarieres(state, action) {
            console.log(state, action);
        }
    },
	extraReducers: {
		[getData.pending]: (state) => {
			state.loading = true
		},
		[getData.rejected]: (state, action) => {
            console.log(action)
			state.loading = false
			state.error = action.error
		},
		[getData.fulfilled]: (state, action) => {
            const { uniqueId, kovarieres, dictionary, fields, correlations } = action.payload;

            state.loading = false;
            
            if (dictionary) state.dictionary = dictionary;
            if (uniqueId) state.dataId = uniqueId;
            if (kovarieres) state.kovarieres = kovarieres;
            if (correlations) state.correlations = correlations;
            if (fields) state.fields = fields;
        }
	},
});

// Generic
export const getDefaultFields = state => state.app.defaultFields;
export const getDictionary = state => state.app.dictionary;

const { reducer, actions } = app;

export const { updateFields } = actions;

export default reducer;
