import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appReducer from './appSlice';
import currentReturnReducer from '@features/CurrentReturn/currentReturnSlice';
import simulatedReturnReducer from '@features/SimulatedReturn/simulatedReturnSlice';

const rootReducer = combineReducers({
    app: appReducer,
    currentReturn: currentReturnReducer,
    simulatedReturn: simulatedReturnReducer
});

const persistConfig = {
    key: 'root',
    blacklist: ['dictionary'],
    storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false
    }),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;