import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './heading.scss';

export const Heading = ({headingLevel, headingClass, children, className}) => {
    const Tag = headingLevel;

    return (
        <Tag className={cx('heading', `heading--${headingClass}`, className)}>{children}</Tag>
    )
}

Heading.propTypes = {
    headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    as: PropTypes.oneOf(['h1', 'h2'])
}

