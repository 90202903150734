import React from 'react';
import PropTypes from 'prop-types';
import './form.scss';

export const Form = ({ children }) => (
    <form autoComplete="off" className="form">
        {children}
    </form>
);

Form.propTypes = {
    children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.string,
	])
}