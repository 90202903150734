import React from 'react';
import { Button } from '@components';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './toggle.scss';

export const Toggle = ({clickHandler, isOpen, className, openText, closeText}) => (
    <div className={cx('toggle', className)}>
        <hr className="toggle__divider" />
        <Button clickHandler={clickHandler} type="button" className="toggle__button button--secondary button--small">
            <span className="toggle__text button__text">
                {isOpen
                    ? <><span className="toggle__icon">–</span><span>{closeText}</span></>
                    : <><span className="toggle__icon">+</span><span>{openText}</span></>
                }
            </span>
        </Button>
    </div>
);

Toggle.propTypes = {
    clickHandler: PropTypes.func,
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    openText: PropTypes.string,
    closeText: PropTypes.string
}
