import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from '@utilities';
import { ReturnCard } from '@components';
import { getDictionary } from '@app/appSlice';
import { updateFields } from './currentReturnSlice';

export const CurrentReturn = () => {
    const dispatch = useDispatch();

    const texts = useSelector(state => getDictionary(state));
    const { fields, sum, activeFields, co2Savings, yearOneReturn, risk } = useSelector(state => state.currentReturn);
    const { kovarieres } = useSelector(state => state.app);
    const tempFields = [...fields];

    const calculateFields = debounce(() => {
        dispatch(updateFields({ fields: tempFields, kovarieres }))
    }, 1000);

    const valueChange = ({ floatValue, id }) => {
        const index = tempFields.findIndex(field => field.id === id);
        const field = {...tempFields[index], value: floatValue};
        tempFields[index] = field;

        calculateFields();
    };

    return (
        <ReturnCard 
            fields={fields}
            annualReturn={yearOneReturn}
            activeFields={activeFields}
            sum={sum}
            currency="DKK"
            onChange={valueChange}
            heading={texts?.currentReturnForecast}
            co2Savings={co2Savings}
            risk={risk}
            open={!activeFields.length} />
    );
};