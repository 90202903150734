import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './button.scss';

export const Button = ({children, clickHandler, className, ...props}) => (
    <button onClick={clickHandler} className={cx('button', className)} {...props}>{children}</button>
);

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.string,
    ]),
    clickHandler: PropTypes.func
}