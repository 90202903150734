import React from 'react';
import { useSelector } from 'react-redux';
import { Heading, PrintButton, DisclaimerButton } from '@components';
import logo from '@static/obton_logo.svg';

import './header.scss';

export const Header = () => {
    const texts = useSelector(state => state?.app?.dictionary);

    return (
        <div className="header print-hidden">
            <div className="container">
                <div className="grid grid--align-center grid--space-between grid--wrap"> 
                    <div className="grid__cell grid__cell--width-12 grid__cell--width-8@sm" role="banner">
                        <div className="header__identity ">
                            <img className="header__logo" src={logo} alt="Obton Afkastberegner" />
                            <Heading className="header__heading" headingLevel="h1" headingClass="h1">{texts?.title}</Heading>
                        </div>
                    </div>
                    <div className="grid__cell grid__cell--width-12 grid__cell--width-4@sm">
                        <div className="header__button-wrapper print-hidden">
                            <div className="grid grid--gutter-sm">
                                <div className="grid__cell grid__cell--flex">
                                <DisclaimerButton /><PrintButton className="header__print" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};