import React from 'react';
import cx from 'classnames';

import './chart-label.scss';

export const ChartLabel = ({ children, color, className }) => (
    <div className={cx('chart-label', className)}>
        <span className="chart-label__color" style={{ boxShadow: `inset 0 0 0 100rem ${color}`}}></span>
        {children}
    </div>
);