import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { Card } from '@containers';
import { Heading, Button, LineChart, ChartLabel, Badge } from '@components';
import { ReturnCardOverview } from './ReturnCardOverview/ReturnCardOverview';
import { ReturnCardForm } from './ReturnCardForm/ReturnCardForm';
import iconEdit from "@static/obton_edit.svg";
import iconCalc from '@static/obton_calculate.svg';
import { sortArrayByKey } from '@utilities';

import './return-card.scss';

export const ReturnCard = ({ heading, fields, activeFields, currency, sum, onChange, annualReturn, returnSummary, badge, co2Savings, risk, annualReturnDiff, riskDiff, transferFields }) => {
    const texts = useSelector(state => state?.app?.dictionary);
    const [isOpen, setIsOpen] = useState(true);
    const showReturnSummary = returnSummary && returnSummary[0].simulated !== 0 && returnSummary[0].current !== 0;

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const orderedFields = sortArrayByKey([...fields], 'order');

    return (
        <div className="return-card print-break-before">
            <div className="return-card__wrapper">
                <Card isOpen={showReturnSummary ? true : isOpen} isActive={activeFields.length > 0}>
                    <div className="return-card__header margin-bottom--lg u-text-center">
                        <Heading headingLevel="h2" headingClass="h1">{heading}</Heading>
                        {badge && <Badge className="return-card__badge print-hidden">{badge}</Badge>}
                    </div>
                    <ReturnCardOverview texts={texts} annualReturn={annualReturn} activeFields={activeFields} co2Savings={co2Savings} risk={risk} annualReturnDiff={annualReturnDiff} riskDiff={riskDiff} />
                </Card>
                {returnSummary &&
                    <AnimateHeight height={ showReturnSummary ? 'auto' : 0}  duration={ 300 }>
                        <Card bgColor="gray" isActive={true} alignTop={true} isOpen={isOpen} className="print-avoid-break"> 
                            <Heading headingLevel="h2" headingClass="h1" className="margin-bottom--lg u-text-center">{texts?.compareReturn}</Heading>
                            <div className="margin-bottom--lg">
                                <LineChart data={returnSummary} />
                            </div>
                            <div className="grid grid--row-gap grid--wrap">
                                <ChartLabel className="grid__cell grid__cell--width-12 grid__cell--width-6@sm" color="var(--color-line-chart-current)">{texts?.currentAssets}</ChartLabel>
                                <ChartLabel className="grid__cell grid__cell--width-12 grid__cell--width-6@sm" color="var(--color-line-chart-simulated)">{texts?.simulatedAssets}</ChartLabel>
                            </div>
                        </Card>
                    </AnimateHeight>
                }
                <AnimateHeight height={ isOpen ? 'auto' : 0}  duration={ 300 } >
                    <ReturnCardForm transferFields={transferFields} fields={orderedFields} currency={currency} onChange={onChange} sum={sum} />
                </AnimateHeight>
            </div>
            <div className="return-card__toggle print-hidden">
                <Button className="button--primary" clickHandler={toggleOpen}>
                    <img className="button__icon margin-right--xs" alt="" src={isOpen ? iconCalc : iconEdit} />
                    {isOpen ? texts?.calculate : texts?.edit}
                </Button>
            </div>
        </div>
    )
}