import React from 'react';
import {
  LineChart as ReChartLineChart, Line, YAxis, ReferenceLine, ResponsiveContainer, Tooltip
} from 'recharts';
import { addDelimiters } from '@utilities';

import './line-chart.scss';

const Tick = (props) => {
  return (
    <text width={props.width} 
          height={props.height}
          x={props.x} 
          y={props.y}
          stroke={props.stroke}
          fill={props.fill}
          className="recharts-text recharts-cartesian-axis-tick-value fade-in line-chart__tick"
          textAnchor={props.textAnchor}>
            <tspan x={props.x} dy="0.355em">DKK {addDelimiters(props.payload.value)}</tspan></text>
  );
}

<text width="60" height="471.5" x="914" y="10" stroke="none" fill="#666" class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="start"><tspan x="914" dy="0.355em">25000000</tspan></text>

const CustomTooltip = (props) => {
  if (!props.active) return null;

  return (
    <div className="line-chart__tooltip fade-in">
      <p className="line-chart__tooltip-text"><b>{props.payload[0].payload.name}</b></p>
      <p className="line-chart__tooltip-text">Aktuelle aktiver: <b>DKK {addDelimiters(props.payload[0].payload.current)}</b></p>
      <p className="line-chart__tooltip-text">Simulerede aktiver: <b>DKK {addDelimiters(props.payload[0].payload.simulated)}</b></p>
    </div>
  )
}

export const LineChart = ({data}) => {
    const length = data.length;

    if (!data.length) return null;

    const simulatedStart = data[0].simulated;
    const simulatedEnd = data[length - 1].simulated;

    const currentStart = data[0].current;
    const currentEnd = data[length - 1].current;

    const characters = Math.max(simulatedEnd.toString().length, currentEnd.toString().length);
    const chartPadding = characters * 7;

    const domainMin = Math.min(simulatedStart, currentStart);
    const domainMax = Math.max(simulatedEnd, currentEnd);

    return (
    <ResponsiveContainer width="100%" aspect={2.5} minHeight="0.1rem">
        <ReChartLineChart className="line-chart" data={data} margin={{ top: 10, bottom: 10, right: chartPadding, left: chartPadding }}>
            <ReferenceLine yAxisId="simulated" y={simulatedEnd} stroke="var(--color-green-spring)" strokeDasharray="3 3" />
            <ReferenceLine yAxisId="current" y={currentEnd} stroke="var(--color-green-spring)" strokeDasharray="3 3" />
            <YAxis tickMargin={10} tick={<Tick />} yAxisId="current" orientation="right" ticks={[currentEnd, simulatedEnd]} type="number" domain={[domainMin, domainMax]} axisLine={false} tickLine={false} />
            <YAxis tickMargin={10} tick={<Tick />} yAxisId="simulated" orientation="left" ticks={[currentStart, simulatedStart]} type="number" domain={[domainMin, domainMax]} axisLine={false} tickLine={false} />
            <Line strokeWidth={3} yAxisId="current" type="monotone" dataKey="current" stroke="var(--color-line-chart-current)" dot={{ fill: "var(--color-gallery)", stroke: 'var(--color-line-chart-current)', strokeWidth: 3, r: 3.5 }} activeDot={{ fill: "var(--color-line-chart-current)", stroke: 'var(--color-line-chart-current)' }}/>
            <Line strokeWidth={3} yAxisId="simulated" type="monotone" dataKey="simulated" stroke="var(--color-line-chart-simulated)" dot={{ fill: "var(--color-gallery)", stroke: 'var(--color-line-chart-simulated)', strokeWidth: 3, r: 3.5 }} activeDot={{ fill: 'var(--color-line-chart-simulated)', stroke: 'var(--color-line-chart-simulated)' }}/>
            <Tooltip content={<CustomTooltip />} />
        </ReChartLineChart>
      </ResponsiveContainer>
    );
}
