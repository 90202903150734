import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './form-field.scss';

export const FormField = ({className, children}) => (
    <div className={cx("form-field print-avoid-break", className)}>
        {children}
    </div>
)

FormField.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.string,
	])
}