import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './card.scss';

export const Card = ({ children, isOpen, isActive, bgColor, alignTop, className }) => (
    <div className={cx('card', {'is-open': isOpen }, {'is-active': isActive }, {'card--gray': bgColor === 'gray'}, {'card--align-top': alignTop}, className)}>
        <div className="card__content">
            {children}
        </div>
    </div>
);

Card.propTypes = {
    children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.string,
    ]),
    isOpen: PropTypes.bool,
    isActive: PropTypes.bool,
    bgColor: PropTypes.oneOf(['white', 'gray']),
    alignTop: PropTypes.bool,
    className: PropTypes.string
}