import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import iconTooltip from '@static/icon-tooltip.svg';
import ReactTooltip from 'react-tooltip';

import './input-label.scss';

export const InputLabel = ({children, description, forId, className, tooltip}) => (
    <div className={cx('input-label', className)}>
        <label className="input-label__label" htmlFor={forId}>
            <span className="input-label__text">
                {children}
            </span>
            {description && 
                <span className="input-label__description">{description}</span>
            }
        </label>
        {tooltip &&
            <div className="input-label__icon" data-tip={tooltip}>
                <img src={iconTooltip} alt="" className="input-label__icon-asset"/>
                <ReactTooltip className="input-label__tooltip" type="light" />
            </div>
        }
    </div>
)

InputLabel.propTypes = {
    description: PropTypes.string,
    forId: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.string,
    ]),
    tooltip: PropTypes.string
}