import React from 'react';
import { Button } from '@components';
import cx from 'classnames';
import printLogo from '@static/obton_print.svg';

import './print-button.scss';

export const PrintButton = ({ className }) => (
    <Button className={cx('print-button button--round button--primary', className)} clickHandler={() => window.print()}>
        <img src={printLogo} className="button__icon" alt="print" />
    </Button>
);