import React from 'react';
import { Button } from '@components';
import cx from 'classnames';
import Popup from 'reactjs-popup';
import './disclaimer-button.scss';
import '../Modal/modal.scss';
import disclaimerLogo from '@static/icon-tooltip.svg';

export const DisclaimerButton = ({ className }) => {

	return (
		<Popup
			trigger={
				<Button className={cx('disclaimer-button button--round button--primary print-hidden', className)}>
					<img src={disclaimerLogo} className="button__icon" alt="disclaimer" />
				</Button>
			}
			modal
			nested
		>
			{close => (
				<div className="modal fade-in">
					<button className="modal__close" onClick={close}>
						&times;
					</button>
					<h2 className="modal__header u-text-center"> Introduktion til afkast- og risikoberegner </h2>
					<div className="modal__content">
						{' '}
						Når man investerer, er der to forhold, der primært driver én og som bør være lige vigtige: Afkast og risiko. Vi har udviklet denne beregner for at give dig et værktøj til at kunne estimere et potentielt afkast af dine samlede investeringer, men lige så vigtigt at give dig et billede af, hvilken potentiel risiko du har ved den sammensætning, du har valgt i din portefølje. Afkast- og risikoberegneren kan ikke træde i stedet for investeringsrådgivning, med er alene et værktøj, du kan bruge for at danne dig et overblik over forskellige scenarier i forbindelse med eventuel fremtidig investering.
						<br /><br />
						Beregneren kan du også anvende til at foretage simuleringer, hvor du kan ændre på, hvordan din portefølje er sammensat, og se, hvilke konsekvenser en ændring heraf vil få for dit potentielle afkast og risiko. Beregneren kan således udregne, hvordan dit potentielle afkast og risiko kan stige eller falde i forhold til din nuværende portefølje og den simulerede portefølje. Beregneren estimerer også den forventede formueudvikling på 10 år for din nuværende og simulerede portefølje.
						<br /><br />
						Beregneren er konstrueret på baggrund af data fra Rådet for <a href="https://www.afkastforventninger.dk" target="_blank" rel="noreferrer">Afkastforventninger</a>, der to gange årligt kommer med forventninger til afkast og risiko for 10 aktivklasser. Rådet for Afkastforventninger er et uafhængigt og sagkyndigt råd, som er nedsat af Forsikring & Pension og Finans Danmark. Rådet består af tre uafhængige eksperter. Beregneren inddrager således Rådet for Afkastforventningers forventninger til afkast og risiko for de 10 aktivklasser 10 år frem i tiden. Derudover har vi tilføjet 3 yderligere aktivklasser til beregneren, i form af kontanter samt vores egne produkter baseret på investeringer i solcelleanlæg, Obton Solar Safe og Obton Solar Upside. Beregneren anvender således disse i alt 13 aktivklasser og det forventede, aggregerede afkast og risiko herfor.
						<br /><br />
						Vi gør opmærksom på, at de i afkast- og risikoberegneren sammenlignede aktivtyper er af meget forskelligartet karakter, hvor der inden for de enkelte aktivklasser også kan være stor varians i afkast og risici. De beregnede potentielle afkast og risici er ikke direkte sammenlignelige, da beregneren blandt andet sammenligner gennemsnitlige standardafkast og -risiko baseret på data fra Rådet for Afkastforventninger med et forventede, fremtidige afkast og risiko for Obtons egne investeringsprodukter, Obton Solenergi Solar og Obton Solar Upside. Det forventede, fremtidige afkast og risiko for Obton Solar Safe og Obton Solar Upside er baseret på Obton Forvaltning A/S begrundede, dokumenterede skøn og objektivt identificerede, rimelige forudsætninger. Samtlige data er baseret på løbende opdaterede.
						<br /><br />
						Obton Forvaltning A/S tager forbehold for, at beregneren alene er tænkt som et værktøj, der kan give dig et overblik over estimerede, potentielle afkast og risici ved investering i de inkluderede aktivklasser. Vi tager desuden forbehold for, at de potentielle afkast og risici alene er vejledende og at der derfor ingen garanti er for, hvordan fremtiden rent faktisk vil udvikle sig de næste 10 år. De anvendte estimater af fremtidige resultater er baseret på historiske data eller hidtidige erfaringer om, hvordan værdien af en investering varierer og/eller bestående markedsvilkår, og er derfor ikke nøjagtige indikatorer. Det årlige afkast og risici afhænger af en lang række faktorer, eksempelvis hvordan markedet fungerer, sammensætningen af din portefølje og hvor længe, du beholder investeringerne.
						<br /><br />
						Afkast- og risikoberegneren og dennes resultater udgør hverken anbefaling af eller rådgivning om investeringsprodukter eller rådgivning om investering i øvrigt. Afkast- og risikoberegneren kan således ikke træde i stedet for rådgivning, og Obton anbefaler, at du kontakter din egen investerings- og/eller skatterådgiver, hvis beregneren giver anledning til spørgsmål. Data for afkast og risiko opdateres i takt med, at Rådet for Afkastforventninger publicerer ny data 2 gange årligt.
						<br /><br />
						Har du spørgsmål til beregneren, er du altid velkommen til at kontakte Obton Forvaltning A/S.
						<br /><br />
						<i>* afkastet i Obton Solar er opgjort til 6,5% (IRR) efter alle omkostninger. </i>
						<br /><br />
						<i>** standardafvigelsen på Obton Solar Safe og Obton Solar Upside er beregnet ud fra datasæt anvendt til beregning af ”Den summariske risikoindikator” og CESR guidelines.</i>
						<br /><br />
						<i>*** kontanter er sat til ”0” i risiko og ”0” i afkast, selvom virkeligheden i dag for mange er negative renter. </i>
						<br /><br />
						<i>Kilder, MSCI World Indeks,  ”Den summariske risikoindikator” for Obton Solenergi Impact beregnet ud fra EU`s PRIPP forordning.,  <a href="https://www.afkastforventninger.dk/media/1489/aendringer-i-afkastforventninger-1-halvaar-2022.pdf" target="_blank" rel="noreferrer">https://www.afkastforventninger.dk/media/1489/aendringer-i-afkastforventninger-1-halvaar-2022.pdf</a> og 10_108.pdf (europa.eu) og 10_108.pdf (europa.eu) </i>
						<br /><br />
					</div>
				</div>
			)}
		</Popup>

	)
}