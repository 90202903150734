//import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './input-field.scss';

export const InputField = ({id, onValueChange, prefix, className, displayType, value }) => (
    <div className={cx('input-field', {'input-field--text': displayType === 'text'}, className)}>
        <NumberFormat
            thousandSeparator={'.'}
            decimalSeparator={false}
            allowNegative={false}
            onValueChange={onValueChange}
            placeholder="0"
            required={true}
            pattern="[\d\.]*"
            className="input-field__input"
            displayType={displayType}
            value={value !== null ? value : ''}
            id={id} />
        {prefix && <span className="input-field__prefix">{prefix}</span>}
    </div>
)

InputField.defaultProps = {
    displayType: 'input'
}

InputField.propTypes = {
    id: PropTypes.string,
    onValueChange: PropTypes.func,
    prefix: PropTypes.string,
    defaultValue: PropTypes.number,
    value: PropTypes.number,
    className: PropTypes.string,
    displayType: PropTypes.oneOf(['text', 'input'])
}