import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from '@utilities';
import { ReturnCard } from '@components';
import { getDictionary } from '@app/appSlice';
import { updateFields, getReturnSummary, getAnnualReturnDiff, getRiskDiff } from './simulatedReturnSlice';

export const SimulatedReturn = () => {
    const dispatch = useDispatch();

    const texts = useSelector(state => getDictionary(state));
    const { fields, sum, activeFields, co2Savings, yearOneReturn, risk } = useSelector(state => state.simulatedReturn);
    const { fields: currentReturnFields } = useSelector(state => state.currentReturn);
    const { kovarieres } = useSelector(state => state.app);
    const annualReturnDiff = useSelector(state => getAnnualReturnDiff(state));
    const riskDiff = useSelector(state => getRiskDiff(state));
    
    const tempFields = [...fields];

    const calculateFields = debounce(() => {
        dispatch(updateFields({ fields: tempFields, kovarieres }))
    }, 1000);

    const valueChange = ({ floatValue, id}) => {
        
        const index = tempFields.findIndex(field => field.id === id);
        const field = {...tempFields[index], value: floatValue};
        tempFields[index] = field;

        calculateFields();
    }

    const transferFields = () => {
        dispatch(updateFields({ fields: currentReturnFields, kovarieres }));
    };

    const data = useSelector(state => getReturnSummary(state));

    return (       
        <ReturnCard 
            fields={fields}
            annualReturn={yearOneReturn}
            activeFields={activeFields}
            sum={sum}
            currency="DKK"
            onChange={valueChange}
            heading={texts?.simulatedReturnForecast}
            returnSummary={data}
            co2Savings={co2Savings}
            risk={risk}
            annualReturnDiff={annualReturnDiff}
            riskDiff={riskDiff}
            badge={texts?.simulation}
            transferFields={transferFields} />
    );
};