import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import cx from 'classnames';
import { radian, getDonutChartColor } from '@utilities';

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {

  if (percent < 0.01) return;

 	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * radian);
  const y = cy  + radius * Math.sin(-midAngle * radian);
  
  return (
    <text className="text" x={x} y={y} fill="white" textAnchor="middle" 	dominantBaseline="central">
    	{`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const DonutChart = ({ data, className }) => {
  const showPlaceholder = data.length === 0;

  return (
    <ResponsiveContainer width="100%" aspect={1} className={cx("donut", className)}>
        <PieChart >
            <Pie
            data={showPlaceholder ? [{value: 1}] : data} 
            cx='50%' 
            cy='50%' 
            labelLine={false}
            label={showPlaceholder ? false : renderCustomizedLabel}
            outerRadius="100%"
            innerRadius="60%"
            fill="var(--color-silver)"
            blendStroke={true}
            isAnimationActive={!showPlaceholder}
            dataKey="value"
            animationDuration={500}>
            {
                data.map((entry, index) => <Cell key={index} fill={getDonutChartColor(entry.id)}/>)
            }
            </Pie>
        </PieChart>
    </ResponsiveContainer>
  )
};

DonutChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
}
