import { useRef, useEffect } from 'react';

export const addDelimiters = string => string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const getSumByKey = (array, key) => array.reduce((a, b) => a + (b[key] || 0), 0);

export const getAverageByKey = (array, key) => getSumByKey(array, key) / array.length;

export const debounce = (fn, wait) => {
	var timeout;
	return function() {
		var context = this;
        var args = arguments;
		
        clearTimeout(timeout);
        
		timeout = setTimeout(function() {
		    fn.apply(context, args);
		}, wait);
	};
};

export const usePrevious = (value) => {
    const ref = useRef();
    
    useEffect(() => {
      ref.current = value;
    });

    return ref.current;
}

export const sortArrayByKey = (array, key) => {
    return array.sort((a, b) => {
        if ((a[key] < b[key]) || (a[key] && !b[key])) return -1;
        if ((a[key] > b[key]) || (!a[key] && b[key])) return 1;
        if (a[key] === b[key]) return 0;
        return 0;
    })
};