import { createSlice } from '@reduxjs/toolkit';
import { calculateAccumulatedReturn, calculateActiveFields, calculateSum, calculateAccumulatedCapital, calculateRisk, calculateCo2Savings, calculateVariance } from '@app/helpers';

const initialState = {
    fields: [],
    activeFields: [],
    accumulatedReturn: [],
    accumulatedCapital: [],
    yearOneReturn: 0,
    co2Savings: 0,
    risk: 0,
    sum: 0
};

const app = createSlice({
    name: 'currentReturn',
	initialState,
	reducers: {
        updateFields(state, action) {
            const { fields, kovarieres } = action.payload;

            const sum = calculateSum(fields);
            const activeFields = calculateActiveFields(fields);
            const accumulatedReturn = calculateAccumulatedReturn(activeFields, 11);
            const accumulatedCapital = calculateAccumulatedCapital(accumulatedReturn, sum);
            const co2Savings = calculateCo2Savings({ fields: activeFields });
            const yearOneReturn = accumulatedReturn[1] ? accumulatedReturn[1] * 100 : 0;
            const variance = calculateVariance({ fields, kovarieres });
            const risk = calculateRisk({ fields, variance });

            state.sum = sum;
            state.activeFields = activeFields;
            state.accumulatedReturn = accumulatedReturn;
            state.accumulatedCapital = accumulatedCapital
            state.co2Savings = co2Savings;
            state.yearOneReturn = yearOneReturn;
            state.fields = fields;
            state.variance = variance;
            state.risk = risk;
        }
	}
});

export const getYearOneReturn = state => state.currentReturn.accumulatedReturn[1] ? state.currentReturn.accumulatedReturn[1] * 100 : 0;

const { reducer, actions } = app;

export const { updateFields } = actions;

export default reducer;
