import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './badge.scss';

export const Badge = ({ children, className }) => (
    <div className={cx('badge', className)}>{children}</div>
);

Badge.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.string,
    ])
}